/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
  
  function affixpos() {
  return $(window).height() - 50 ;
  }
  
  function topimageHeight() {
	return parseInt($(window).height() * 0.66667);
  }
  
  function headerHeight() {
if($("body").hasClass("frontpage")) {
	var hh = $(window).height() - topimageHeight() -10;
	
	if(hh < 88) {
		$("header.banner").removeClass( "o175 o210 o250" ).addClass( "u88" );
	} else if(hh > 164 && hh < 201) {
		$("header.banner").removeClass( "u88 o210 o250" ).addClass( "o175" );
	} else if(hh > 200 && hh < 240) {
		$("header.banner").removeClass( "u88 o175 o250" ).addClass( "o210" );
	} else if(hh > 239) {
		$("header.banner").removeClass( "u88 o175 o210" ).addClass( "o250" );
	} else {
		$("header.banner").removeClass( "u88 o175 o210 o250" );
	}
  
	return hh;
} else {
	return 0;
}
	}

  
  
  
  function resize_topimage() {
  	if($("body").hasClass("frontpage")) {
		$(".topimage").height(topimageHeight);
		$('#headermenu').affix({
		  offset: {
			top: function() { return affixpos(); }
			}
		});
		$(".affix-top").height(headerHeight);
		$(".header-spacer").height(headerHeight);
	} else {

	}
  }
  
  $(document).ready(function () {
	  resize_topimage();
	$( window ).resize(function() {
		resize_topimage();
	});

		if($("body").hasClass("frontpage")) {
	$("header#headermenu").on("affixed-top.bs.affix", function() {
		$(".affix-top").height(headerHeight);
		$(".header-spacer").height(headerHeight);
	});
	
 
  	$("header#headermenu").on("affixed.bs.affix", function() {
	$(".affix").height(50);
	//$(".header-spacer").height(50);
	});
		}
  });

})(jQuery); // Fully reference jQuery after this point.
